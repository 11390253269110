body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding: 7px 15px 8px 10px !important;
  position: relative !important;
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul > li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
}

